import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { IWorkitem, IWorklist } from "@queue/types";
import { useSelector } from "react-redux";
import { AppState } from "../reducers";
import { database, firestore } from "../firebase";
import { ref } from "firebase/database";
import { useDatabaseValue } from "@react-query-firebase/database";
import {
  collection,
  where,
  orderBy,
  limit,
  query,
  CollectionReference,
} from "firebase/firestore";

export const useWorklistGroups = () => {
  const storeId = useSelector((state: AppState) => state.store.storeId);

  const worklistsRef = ref(
    database,
    `branches/${storeId}/worklistGroups`
  ) as any;

  return useDatabaseValue(["worklistGroups"], worklistsRef) as any;
};

export const useWorklist = (worklistId: string) => {
  const storeId = useSelector((state: AppState) => state.store.storeId);
  const worklistRef = ref(
    database,
    `branches/${storeId}/worklists/${worklistId}`
  );

  return useDatabaseValue<IWorklist>(["worklist", worklistId], worklistRef);
};

export const useWorkitems = (worklistId = "default") => {
  const storeId = useSelector((state: AppState) => state.store.storeId);
  const workitemsRef = query(
    collection(
      firestore,
      "stores",
      storeId,
      "workitems"
    ) as CollectionReference<IWorkitem>,
    where("worklist", "==", worklistId),
    where("status", "==", 0),
    limit(100),
    orderBy("createdAt")
  );

  return useFirestoreQueryData(
    ["workitems", worklistId],
    workitemsRef,
    {
      idField: "id",
      subscribe: true,
    },
    { cacheTime: 0 }
  );
};
