import { OrderSourceType } from "@queue/types";
import React from "react";
import foodpanda from "../img/panda.png";
import uber from "../img/uber.jpg";

interface Props {
  source: OrderSourceType;
}

export const BrandAvatar: React.FC<Props> = ({ source }) => {
  return (
    <>
      {source === OrderSourceType.UBER && (
        <img src={uber} height={40} alt={""} />
      )}
      {source === OrderSourceType.DELIVERY_HERO && (
        <img src={foodpanda} height={40} alt={""} />
      )}
    </>
  );
};
