import { createSlice } from "@reduxjs/toolkit";
import { WorklistGroup } from "@queue/types";

interface State {
  cloudCenterId: string;
  storeId: string;
  worklistGroup: WorklistGroup | null;
  selectedWorkitemId: string;
}

const initialState: State = {
  cloudCenterId: "",
  storeId: "",
  worklistGroup: null,
  selectedWorkitemId: "",
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setStoreId: (state, { payload }) => {
      state.storeId = payload;
    },
    setCloudCenterId: (state, { payload }) => {
      state.cloudCenterId = payload;
    },
    setWorklistGroup: (state, { payload }) => {
      state.worklistGroup = payload;
    },
    setSelectedWorkitemId: (state, { payload }) => {
      state.selectedWorkitemId = payload;
    },
    signOut: () => {
      return initialState;
    },
  },
});
