import {
  selectCartRef,
  selectCloudCenterId,
  selectStoreId,
  selectStoreRef,
  selectWorkitemsRef,
} from "../selectors/store";
import {
  ConsumerOrderType,
  ExternalPlatformOrder,
  IOrder,
  OrderSourceType,
  QmeOrderStatus,
  WorkitemStatus,
  WorkStatus,
} from "@queue/types";
import { functions, firestore } from "../firebase";
import { httpsCallable } from "firebase/functions";
import {
  writeBatch,
  getDoc,
  getDocs,
  where,
  collection,
  query,
  doc,
  serverTimestamp,
} from "firebase/firestore";

export const completeOrderThunk =
  (posOrder: IOrder) => async (dispatch: any, getState: any) => {
    const batch = writeBatch(firestore);

    const state = getState();

    const storeId = selectStoreId(state);
    const cloudCenterId = selectCloudCenterId(state);
    const cartRef = selectCartRef(state);
    const storeRef = selectStoreRef(state);
    const workitemsRef = selectWorkitemsRef(state);

    const callOrderPrepared = httpsCallable(functions, "deliveryHeroPrepared");

    const callUpdateOrderStatus = httpsCallable(
      functions,
      "deliveryHeroUpdateOrder"
    );

    const lineitemsQuery = query(
      collection(cartRef, "openLineItems"),
      where("order", "==", posOrder.id)
    );

    const lineitemsSnap = await getDocs(lineitemsQuery);

    const workitemsQuery = query(
      workitemsRef,
      where("order", "==", posOrder.id)
    );

    const workitemsSnap = await getDocs(workitemsQuery);

    let _collection = "";

    if (posOrder.source === OrderSourceType.UBER) {
      _collection = "uberEats";
    } else if (posOrder.source === OrderSourceType.DELIVERY_HERO) {
      _collection = "deliveryHero";
    } else {
      throw new Error("missing source type");
    }

    const consumerOrderRef = doc(storeRef, _collection, posOrder.id);

    const orderSnap = await getDoc(consumerOrderRef);

    const externalOrder = orderSnap.data() as ExternalPlatformOrder;

    if (!externalOrder) {
      throw new Error("missing external order");
    }

    if (
      externalOrder.orderType === ConsumerOrderType.PlatformDelivery &&
      externalOrder.source === OrderSourceType.DELIVERY_HERO
    ) {
      try {
        await callOrderPrepared({
          storeId,
          orderToken: posOrder.externalOrderId,
          orderId: posOrder.id,
        });
      } catch (e) {
        return;
      }
    }

    if (cloudCenterId) {
      const cloudCenterRef = doc(
        firestore,
        "cloudCenters",
        cloudCenterId,
        "deliveryOrders",
        externalOrder.id
      );

      batch.update(cloudCenterRef, {
        status: "kitchen_ready",
        kitchenReadyAt: serverTimestamp(),
      });
    }

    batch.update(consumerOrderRef, {
      status: QmeOrderStatus.Ready,
      readyAt: new Date(),
      readyBy: "kds",
    });

    try {
      if (
        (externalOrder.orderType === ConsumerOrderType.Pickup ||
          externalOrder.orderType === ConsumerOrderType.SelfDelivery) &&
        externalOrder.source === OrderSourceType.DELIVERY_HERO
      ) {
        await callUpdateOrderStatus({
          storeId,
          orderToken: externalOrder.externalOrderId,
          orderId: externalOrder.id,
          status: QmeOrderStatus.Completed,
          deliveryHeroStatus: "order_picked_up",
        });
      }

      batch.update(consumerOrderRef, {
        status: QmeOrderStatus.Completed,
        completedAt: new Date(),
      });

      lineitemsSnap.forEach((snap) => {
        batch.delete(snap.ref);
      });

      workitemsSnap.forEach((snap) => {
        batch.update(snap.ref, {
          completedAt: Date.now(),
          status: WorkitemStatus.Completed,
          workstatus: WorkStatus.Completed,
        });
      });

      const openOrderRef = doc(cartRef, "openOrders", externalOrder.id);

      batch.delete(openOrderRef);
      batch.commit();

      return true;
    } finally {
    }
  };
