import React, { useCallback, useMemo } from "react";
import { useWorklist, useWorkitems } from "../hooks/useWorklists";
import { Card, Typography, CardHeader } from "@mui/material";
import { Workitem } from "./Workitem";
import { FullScreenHandle } from "react-full-screen";
import FlexView from "react-flexview";
import useDimensions from "react-cool-dimensions";
import { Scrollbars } from "react-custom-scrollbars-2";

export const calculateBestWidth = (width: number, size: number) => {
  if (!size) {
    return 0;
  }

  const itemCount = Math.floor(width / size);

  // Return the width if it can hold less than a
  // single card
  if (!itemCount) {
    return size;
  }
  return width / itemCount;
};

interface Props {
  worklistId: string;
  handleFullscreen: FullScreenHandle;
}

export const Worklist: React.FC<Props> = (props) => {
  const worklist = useWorklist(props.worklistId);
  const workitems = useWorkitems(props.worklistId);
  const { observe, width: screenWidth } = useDimensions();

  const toggleFullscreen = useCallback(() => {
    if (props.handleFullscreen.active) {
      props.handleFullscreen.exit();
    } else {
      props.handleFullscreen.enter();
    }
  }, [props.handleFullscreen]);

  const itemWidth = useMemo(
    () => calculateBestWidth(screenWidth, 400),
    [screenWidth]
  );

  return (
    <div
      className="worklistColumn"
      ref={observe}
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: itemWidth - 20,
        height: "100%",
      }}
    >
      <Card
        onClick={toggleFullscreen}
        style={{
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
          justifyContent: "space-between",
          flexDirection: "row",
          display: "flex",
          marginLeft: 10,
          marginRight: 10,
          marginTop: 10,
        }}
      >
        <CardHeader title={worklist?.data?.name || "Default"} />
        <Typography variant="h3" style={{ margin: 5 }}>
          {workitems?.data?.length || 0}
        </Typography>
      </Card>

      <Scrollbars style={{ height: "90vh" }} autoHide>
        <FlexView
          wrap
          style={{
            flex: 1,
          }}
        >
          {workitems?.data?.map((workitem) => {
            return (
              <div style={{ width: itemWidth }} key={workitem.id}>
                <Workitem workitem={workitem} />
              </div>
            );
          })}
        </FlexView>
      </Scrollbars>
    </div>
  );
};

Worklist.whyDidYouRender = true;
