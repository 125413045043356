import React from "react";
import { map } from "lodash";
import { useTheme } from "@mui/material";
import { WorklistGroup } from "./WorklistGroup";
import { FullScreenHandle } from "react-full-screen";
import { useAppSelector } from "../../hooks/redux";
import { selectWorklistGroup } from "../../selectors/store";
import { KDSOffline } from "../Offline";
import FlexView from "react-flexview";

interface Props {
  handleFullscreen: FullScreenHandle;
}

export const WorklistOrders: React.FC<Props> = ({ handleFullscreen }) => {
  const group = useAppSelector(selectWorklistGroup);

  const theme = useTheme();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <KDSOffline />
      <FlexView style={{ flex: 1 }}>
        {map(group?.worklists, (_, worklistId) => (
          <WorklistGroup
            key={worklistId}
            worklistId={worklistId}
            handleFullscreen={handleFullscreen}
          />
        ))}
      </FlexView>
    </div>
  );
};
