"use strict";
exports.__esModule = true;
exports.checkCompanyId = exports.loveCodeRegex = exports.carrierRegex = exports.NTD = exports.CustomerSystem = exports.RangeInterval = exports.MenuModifierType = exports.PlatformOrderStatus = exports.CloudCenterOrderType = exports.CloudCenterOrderStatus = exports.UberOrderStatus = exports.UberEatsPendingReject = exports.UberEatOrderType = exports.PlatformPaymentTypes = exports.DeliveryAcceptedReject = exports.DeliveryHeroPendingReject = exports.ConsumerPaymentTypes = exports.Status = exports.WorkitemStatus = exports.OrderStatus = exports.IWebOrderCancelReason = exports.QmeOrderStatus = exports.ConsumerOrderType = exports.StarPrinterTypes = exports.PrintFontSize = exports.StarPrintWidth = exports.PrintWorklistType = exports.PrinterLang = exports.ReportType = exports.CustomerTransactionTypes = exports.QOrderType = exports.PosOrderStatus = exports.OrderKind = exports.MovementType = exports.InventoryType = exports.AdjustmentTypes = exports.CapacityTypes = exports.StorageCenterType = exports.TransferStatus = exports.QPaymentType = exports.OrderSourceType = exports.WorkStatus = exports.SubscriptionStatus = exports.DiscountType = void 0;
var DiscountType;
(function (DiscountType) {
    DiscountType[DiscountType["Flatoff"] = 0] = "Flatoff";
    DiscountType[DiscountType["Bogo"] = 1] = "Bogo";
})(DiscountType = exports.DiscountType || (exports.DiscountType = {}));
var SubscriptionStatus;
(function (SubscriptionStatus) {
    SubscriptionStatus["Free"] = "free";
    SubscriptionStatus["Expired"] = "expired";
    SubscriptionStatus["Yearly"] = "yearly";
    SubscriptionStatus["Monthly"] = "monthly";
    SubscriptionStatus["Trial"] = "trial";
})(SubscriptionStatus = exports.SubscriptionStatus || (exports.SubscriptionStatus = {}));
var WorkStatus;
(function (WorkStatus) {
    WorkStatus["Open"] = "OPEN";
    WorkStatus["PendingComplete"] = "PENDING_COMPLETE";
    WorkStatus["Completed"] = "COMPLETED";
    WorkStatus["Deleted"] = "DELETED";
})(WorkStatus = exports.WorkStatus || (exports.WorkStatus = {}));
var OrderSourceType;
(function (OrderSourceType) {
    OrderSourceType["POS"] = "POS";
    OrderSourceType["Consumer"] = "CONSUMER";
    OrderSourceType["Tab"] = "TAB";
    OrderSourceType["UBER"] = "UBER_EATS";
    OrderSourceType["DELIVERY_HERO"] = "DELIVERY_HERO";
    OrderSourceType["Custom"] = "CUSTOM";
    OrderSourceType["CloudPOS"] = "CLOUD_POS";
})(OrderSourceType = exports.OrderSourceType || (exports.OrderSourceType = {}));
var QPaymentType;
(function (QPaymentType) {
    QPaymentType[QPaymentType["store"] = 0] = "store";
    QPaymentType[QPaymentType["linepay"] = 1] = "linepay";
    QPaymentType[QPaymentType["easywallet"] = 2] = "easywallet";
})(QPaymentType = exports.QPaymentType || (exports.QPaymentType = {}));
var TransferStatus;
(function (TransferStatus) {
    TransferStatus[TransferStatus["Pending"] = 0] = "Pending";
    TransferStatus[TransferStatus["Cancelled"] = 1] = "Cancelled";
    TransferStatus[TransferStatus["Accepted"] = 2] = "Accepted";
    TransferStatus[TransferStatus["Declined"] = 3] = "Declined";
})(TransferStatus = exports.TransferStatus || (exports.TransferStatus = {}));
var StorageCenterType;
(function (StorageCenterType) {
    StorageCenterType[StorageCenterType["Central"] = 0] = "Central";
    StorageCenterType[StorageCenterType["Store"] = 1] = "Store";
})(StorageCenterType = exports.StorageCenterType || (exports.StorageCenterType = {}));
var CapacityTypes;
(function (CapacityTypes) {
    CapacityTypes[CapacityTypes["Pieces"] = 0] = "Pieces";
    CapacityTypes[CapacityTypes["Volume"] = 1] = "Volume";
    CapacityTypes[CapacityTypes["Mass"] = 2] = "Mass";
})(CapacityTypes = exports.CapacityTypes || (exports.CapacityTypes = {}));
var AdjustmentTypes;
(function (AdjustmentTypes) {
    AdjustmentTypes[AdjustmentTypes["Incoming"] = 0] = "Incoming";
    AdjustmentTypes[AdjustmentTypes["Outgoing"] = 1] = "Outgoing";
})(AdjustmentTypes = exports.AdjustmentTypes || (exports.AdjustmentTypes = {}));
var InventoryType;
(function (InventoryType) {
    InventoryType[InventoryType["V1"] = 0] = "V1";
    InventoryType[InventoryType["StoreGroup"] = 1] = "StoreGroup";
    InventoryType[InventoryType["Store"] = 2] = "Store";
})(InventoryType = exports.InventoryType || (exports.InventoryType = {}));
var MovementType;
(function (MovementType) {
    MovementType[MovementType["Adjustment"] = 0] = "Adjustment";
    MovementType[MovementType["Order"] = 1] = "Order";
    MovementType[MovementType["Return"] = 2] = "Return";
})(MovementType = exports.MovementType || (exports.MovementType = {}));
var OrderKind;
(function (OrderKind) {
    OrderKind["POS"] = "pos";
    OrderKind["QUICK"] = "quick";
    OrderKind["TABLE"] = "table";
    OrderKind["CONSUMER"] = "consumer";
    OrderKind["TAB"] = "tab";
})(OrderKind = exports.OrderKind || (exports.OrderKind = {}));
var PosOrderStatus;
(function (PosOrderStatus) {
    PosOrderStatus["open"] = "open";
    PosOrderStatus["paid"] = "paid";
    PosOrderStatus["voided"] = "voided";
    PosOrderStatus["openPaid"] = "openPaid";
})(PosOrderStatus = exports.PosOrderStatus || (exports.PosOrderStatus = {}));
var QOrderType;
(function (QOrderType) {
    QOrderType["Pickup"] = "PICKUP";
    QOrderType["Delivery"] = "DELIVERY";
})(QOrderType = exports.QOrderType || (exports.QOrderType = {}));
var CustomerTransactionTypes;
(function (CustomerTransactionTypes) {
    CustomerTransactionTypes[CustomerTransactionTypes["Bonus"] = 0] = "Bonus";
    CustomerTransactionTypes[CustomerTransactionTypes["Discount"] = 1] = "Discount";
})(CustomerTransactionTypes = exports.CustomerTransactionTypes || (exports.CustomerTransactionTypes = {}));
var ReportType;
(function (ReportType) {
    ReportType[ReportType["Insight"] = 0] = "Insight";
    ReportType[ReportType["Categories"] = 1] = "Categories";
    ReportType[ReportType["Hourly"] = 2] = "Hourly";
    ReportType[ReportType["Revenue"] = 3] = "Revenue";
    ReportType[ReportType["Sales"] = 4] = "Sales";
    ReportType[ReportType["Invoices"] = 5] = "Invoices";
    ReportType[ReportType["Orders"] = 6] = "Orders";
})(ReportType = exports.ReportType || (exports.ReportType = {}));
var PrinterLang;
(function (PrinterLang) {
    PrinterLang[PrinterLang["Escpos"] = 0] = "Escpos";
    PrinterLang[PrinterLang["StarLine"] = 1] = "StarLine";
    PrinterLang[PrinterLang["StarGraphic"] = 2] = "StarGraphic";
    PrinterLang[PrinterLang["EpsonTM"] = 3] = "EpsonTM";
    PrinterLang[PrinterLang["GoDEX"] = 4] = "GoDEX";
})(PrinterLang = exports.PrinterLang || (exports.PrinterLang = {}));
var PrintWorklistType;
(function (PrintWorklistType) {
    PrintWorklistType["SingleCollapsed"] = "single";
    PrintWorklistType["SingleExpanded"] = "singleListQuantity";
    PrintWorklistType["CategoryCollapsed"] = "category";
    PrintWorklistType["TicketPerItemCollapsed"] = "item";
    PrintWorklistType["TicketPerItemExpanded"] = "itemQuantity";
    PrintWorklistType["Sticker"] = "sticker";
    PrintWorklistType["PerComboItem"] = "perComboItem";
})(PrintWorklistType = exports.PrintWorklistType || (exports.PrintWorklistType = {}));
exports.StarPrintWidth = {
    0: 384,
    1: 586
};
exports.PrintFontSize = {
    normal: 12,
    medium: 15,
    large: 20
};
exports.StarPrinterTypes = [
    PrinterLang.StarGraphic,
    PrinterLang.StarLine,
    PrinterLang.EpsonTM,
];
var ConsumerOrderType;
(function (ConsumerOrderType) {
    ConsumerOrderType["InStore"] = "INSTORE";
    ConsumerOrderType["Pickup"] = "PICKUP";
    ConsumerOrderType["Delivery"] = "DELIVERY";
    ConsumerOrderType["SelfDelivery"] = "SELF_DELIVERY";
    ConsumerOrderType["PlatformDelivery"] = "PLATFORM_DELIVERY";
})(ConsumerOrderType = exports.ConsumerOrderType || (exports.ConsumerOrderType = {}));
var QmeOrderStatus;
(function (QmeOrderStatus) {
    QmeOrderStatus["Pending"] = "PENDING";
    QmeOrderStatus["AutoAccepted"] = "AUTO_ACCEPTED";
    QmeOrderStatus["Accepted"] = "ACCEPTED";
    QmeOrderStatus["InProgress"] = "IN_PROGRESS";
    QmeOrderStatus["Completed"] = "COMPLETED";
    QmeOrderStatus["Delivered"] = "DELIVERED";
    QmeOrderStatus["QCancelled"] = "QCANCELLED";
    QmeOrderStatus["UserCancelled"] = "USER_CANCELLED";
    QmeOrderStatus["StoreCancelled"] = "STORE_CANCELLED";
    QmeOrderStatus["Ready"] = "READY";
    QmeOrderStatus["Paid"] = "PAID";
    QmeOrderStatus["PlatformCancelled"] = "PLATFORM_CANCELLED";
    QmeOrderStatus["PlatformCancelledApproved"] = "PLATFORM_CANCELLED_APPROVED";
    QmeOrderStatus["StoreDeclined"] = "STORE_DECLINED";
    QmeOrderStatus["PlatformPendingCancelled"] = "PLATFORM_PENDING_CANCELLED";
    QmeOrderStatus["PlatformPendingCancelledOngoing"] = "PLATFORM_PENDING_CANCELLED_ONGOING";
    QmeOrderStatus["CustomOrderPending"] = "CUSTOM_ORDER_PENDING";
    QmeOrderStatus["Scheduled"] = "SCHEDULED";
    QmeOrderStatus["ScheduledPending"] = "SCHEDULED_PENDING";
})(QmeOrderStatus = exports.QmeOrderStatus || (exports.QmeOrderStatus = {}));
var IWebOrderCancelReason;
(function (IWebOrderCancelReason) {
    IWebOrderCancelReason["noResponse"] = "NO_RESPONSE";
    IWebOrderCancelReason["userCancelled"] = "USER_CANCELLED";
    IWebOrderCancelReason["kitchenDeclined"] = "KITCHEN_DECLINED";
})(IWebOrderCancelReason = exports.IWebOrderCancelReason || (exports.IWebOrderCancelReason = {}));
var OrderStatus;
(function (OrderStatus) {
    OrderStatus[OrderStatus["Open"] = 0] = "Open";
    OrderStatus[OrderStatus["Paid"] = 1] = "Paid";
    OrderStatus[OrderStatus["Deleted"] = 2] = "Deleted";
    OrderStatus[OrderStatus["Merged"] = 3] = "Merged";
    OrderStatus[OrderStatus["OpenPaid"] = 4] = "OpenPaid";
})(OrderStatus = exports.OrderStatus || (exports.OrderStatus = {}));
var WorkitemStatus;
(function (WorkitemStatus) {
    WorkitemStatus[WorkitemStatus["Open"] = 0] = "Open";
    WorkitemStatus[WorkitemStatus["Completed"] = 1] = "Completed";
    WorkitemStatus[WorkitemStatus["Deleted"] = 2] = "Deleted";
    WorkitemStatus[WorkitemStatus["PendingComplete"] = 3] = "PendingComplete";
})(WorkitemStatus = exports.WorkitemStatus || (exports.WorkitemStatus = {}));
var Status;
(function (Status) {
    Status[Status["Visible"] = 0] = "Visible";
    Status[Status["Deleted"] = 1] = "Deleted";
})(Status = exports.Status || (exports.Status = {}));
var ConsumerPaymentTypes;
(function (ConsumerPaymentTypes) {
    ConsumerPaymentTypes["LINE"] = "consumerLinePay";
    ConsumerPaymentTypes["EASYCARD"] = "consumerEasyCard";
    ConsumerPaymentTypes["UBER_EATS"] = "uberEats";
    ConsumerPaymentTypes["FOOD_PANDA"] = "foodpanda";
    ConsumerPaymentTypes["Custom"] = "custom";
})(ConsumerPaymentTypes = exports.ConsumerPaymentTypes || (exports.ConsumerPaymentTypes = {}));
var DeliveryHeroPendingReject;
(function (DeliveryHeroPendingReject) {
    DeliveryHeroPendingReject["closed"] = "CLOSED";
    DeliveryHeroPendingReject["itemUnavailable"] = "ITEM_UNAVAILABLE";
    DeliveryHeroPendingReject["addressIncomplete"] = "ADDRESS_INCOMPLETE_MISSTATED";
    DeliveryHeroPendingReject["badWeather"] = "BAD_WEATHER";
    DeliveryHeroPendingReject["blacklisted"] = "BLACKLISTED";
    DeliveryHeroPendingReject["cardReaderNotAvailable"] = "CARD_READER_NOT_AVAILABLE";
    DeliveryHeroPendingReject["fraudPrank"] = "FRAUD_PRANK";
    DeliveryHeroPendingReject["menuAccountSettings"] = "MENU_ACCOUNT_SETTINGS";
    DeliveryHeroPendingReject["movNotReached"] = "MOV_NOT_REACHED";
    DeliveryHeroPendingReject["noCourier"] = "NO_COURIER";
    DeliveryHeroPendingReject["noPicker"] = "NO_PICKER";
    DeliveryHeroPendingReject["outsideDeliveryArea"] = "OUTSIDE_DELIVERY_AREA";
    DeliveryHeroPendingReject["technicalProblem"] = "TECHNICAL_PROBLEM";
    DeliveryHeroPendingReject["testOrder"] = "TEST_ORDER";
    DeliveryHeroPendingReject["tooBusy"] = "TOO_BUSY";
    DeliveryHeroPendingReject["unprofessionalBehaviour"] = "UNPROFESSIONAL_BEHAVIOUR";
    DeliveryHeroPendingReject["willNotWorkWithPlatform"] = "WILL_NOT_WORK_WITH_PLATFORM";
})(DeliveryHeroPendingReject = exports.DeliveryHeroPendingReject || (exports.DeliveryHeroPendingReject = {}));
var DeliveryAcceptedReject;
(function (DeliveryAcceptedReject) {
    DeliveryAcceptedReject["badWeather"] = "BAD_WEATHER";
    DeliveryAcceptedReject["cardReaderNotAvailable"] = "CARD_READER_NOT_AVAILABLE";
    DeliveryAcceptedReject["closed"] = "CLOSED";
    DeliveryAcceptedReject["contentWrongMisleading"] = "CONTENT_WRONG_MISLEADING";
    DeliveryAcceptedReject["foodQualitySpillage"] = "FOOD_QUALITY_SPILLAGE";
    DeliveryAcceptedReject["lateDelivery"] = "LATE_DELIVERY";
    DeliveryAcceptedReject["unableToFind"] = "UNABLE_TO_FIND";
    DeliveryAcceptedReject["unableToPay"] = "UNABLE_TO_PAY";
    DeliveryAcceptedReject["unprofessionalBehaviour"] = "UNPROFESSIONAL_BEHAVIOUR";
    DeliveryAcceptedReject["wrongOrderItemsDelivered"] = "WRONG_ORDER_ITEMS_DELIVERED";
})(DeliveryAcceptedReject = exports.DeliveryAcceptedReject || (exports.DeliveryAcceptedReject = {}));
var PlatformPaymentTypes;
(function (PlatformPaymentTypes) {
    PlatformPaymentTypes["foodpanda"] = "foodpanda";
    PlatformPaymentTypes["ubereats"] = "ubereats";
})(PlatformPaymentTypes = exports.PlatformPaymentTypes || (exports.PlatformPaymentTypes = {}));
var UberEatOrderType;
(function (UberEatOrderType) {
    UberEatOrderType["pickUp"] = "PICK_UP";
    UberEatOrderType["dineIn"] = "DINE_IN";
    UberEatOrderType["deliveryByUber"] = "DELIVERY_BY_UBER";
    UberEatOrderType["deliveryByRestaurant"] = "DELIVERY_BY_RESTAURANT";
})(UberEatOrderType = exports.UberEatOrderType || (exports.UberEatOrderType = {}));
var UberEatsPendingReject;
(function (UberEatsPendingReject) {
    UberEatsPendingReject["customerCalledToCancel"] = "CUSTOMER_CALLED_TO_CANCEL";
    UberEatsPendingReject["restaurantTooBusy"] = "RESTAURANT_TOO_BUSY";
    UberEatsPendingReject["outOfItems"] = "OUT_OF_ITEMS";
    UberEatsPendingReject["kitchenClosed"] = "KITCHEN_CLOSED";
    UberEatsPendingReject["cannotCompleteCustomerNote"] = "CANNOT_COMPLETE_CUSTOMER_NOTE";
    UberEatsPendingReject["other"] = "OTHER";
})(UberEatsPendingReject = exports.UberEatsPendingReject || (exports.UberEatsPendingReject = {}));
var UberOrderStatus;
(function (UberOrderStatus) {
    UberOrderStatus["created"] = "CREATED";
    UberOrderStatus["accepted"] = "ACCEPTED";
    UberOrderStatus["denied"] = "DENIED";
    UberOrderStatus["cancelled"] = "CANCELLED";
    UberOrderStatus["finised"] = "FINISHED";
})(UberOrderStatus = exports.UberOrderStatus || (exports.UberOrderStatus = {}));
var CloudCenterOrderStatus;
(function (CloudCenterOrderStatus) {
    CloudCenterOrderStatus["frontdeskDelivered"] = "front_delivered";
    CloudCenterOrderStatus["frontdeskReady"] = "front_ready";
    CloudCenterOrderStatus["kitchenAccepted"] = "kitchen_accepted";
    CloudCenterOrderStatus["kitchenCancelled"] = "kitchen_cancelled";
    CloudCenterOrderStatus["kitchenDeclined"] = "kitchen_declined";
    CloudCenterOrderStatus["kitchenPending"] = "kitchen_pending";
    CloudCenterOrderStatus["kitchenReady"] = "kitchen_ready";
    CloudCenterOrderStatus["pendingOther"] = "pending_other";
    CloudCenterOrderStatus["platformCancelled"] = "platform_cancelled";
    CloudCenterOrderStatus["scheduled"] = "scheduled";
})(CloudCenterOrderStatus = exports.CloudCenterOrderStatus || (exports.CloudCenterOrderStatus = {}));
var CloudCenterOrderType;
(function (CloudCenterOrderType) {
    CloudCenterOrderType["Brand"] = "BRAND";
    CloudCenterOrderType["Group"] = "GROUP";
})(CloudCenterOrderType = exports.CloudCenterOrderType || (exports.CloudCenterOrderType = {}));
var PlatformOrderStatus;
(function (PlatformOrderStatus) {
    PlatformOrderStatus["pending"] = "PENDING";
    PlatformOrderStatus["inProgress"] = "IN_PROGRESS";
    PlatformOrderStatus["accepted"] = "ACCEPTED";
})(PlatformOrderStatus = exports.PlatformOrderStatus || (exports.PlatformOrderStatus = {}));
var MenuModifierType;
(function (MenuModifierType) {
    MenuModifierType["productOption"] = "PRODUCT_OPTION";
    MenuModifierType["variant"] = "VARIANT";
})(MenuModifierType = exports.MenuModifierType || (exports.MenuModifierType = {}));
var RangeInterval;
(function (RangeInterval) {
    RangeInterval["Today"] = "TODAY";
    RangeInterval["ThisMonth"] = "THIS_MONTH";
    RangeInterval["ThisYear"] = "THIS_YEAR";
    RangeInterval["LastMonth"] = "LAST_MONTH";
    RangeInterval["LastYear"] = "LAST_YEAR";
    RangeInterval["Custom"] = "CUSTOM";
})(RangeInterval = exports.RangeInterval || (exports.RangeInterval = {}));
var CustomerSystem;
(function (CustomerSystem) {
    CustomerSystem[CustomerSystem["Database"] = 0] = "Database";
    CustomerSystem[CustomerSystem["Group"] = 1] = "Group";
})(CustomerSystem = exports.CustomerSystem || (exports.CustomerSystem = {}));
exports.NTD = {
    code: "NTD",
    base: 10,
    exponent: 0
};
exports.carrierRegex = /^[\dA-Z.\-+]{7}$/;
exports.loveCodeRegex = /^[\d]{3,7}$/;
var checkCompanyId = function (idValue) {
    if (!idValue || idValue.length < 1) {
        return true;
    }
    var tmp = "12121241";
    var sum = 0;
    var re = /^\d{8}$/;
    if (!re.test(idValue)) {
        return false;
    }
    for (var i = 0; i < 8; i++) {
        var s1 = parseInt(idValue.substr(i, 1));
        var s2 = parseInt(tmp.substr(i, 1));
        sum += cal(s1 * s2);
    }
    if (!valid(sum)) {
        if (idValue.substr(6, 1) === "7") {
            return valid(sum + 1);
        }
    }
    return valid(sum);
};
exports.checkCompanyId = checkCompanyId;
function valid(n) {
    return n % 10 === 0;
}
function cal(n) {
    var sum = 0;
    while (n !== 0) {
        sum += n % 10;
        n = (n - (n % 10)) / 10;
    }
    return sum;
}
