import { IOrder } from "@queue/types";
import { useAppSelector } from "./redux";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import {
  collection,
  where,
  orderBy,
  query,
  CollectionReference,
} from "firebase/firestore";
import { firestore } from "../firebase";

interface Params {
  worklistId?: string;
}

export const useOrders = ({ worklistId }: Params) => {
  const storeId = useAppSelector((state) => state.store.storeId);

  const ordersRef = collection(
    firestore,
    "stores",
    storeId,
    "openItems",
    "cart",
    "openOrders"
  ) as CollectionReference<IOrder>;

  let ordersQuery = query(ordersRef, orderBy("createdAt"));

  if (worklistId) {
    ordersQuery = query(
      ordersRef,
      orderBy("createdAt"),
      where("kdsId", "==", worklistId)
    );
  } else {
  }

  return useFirestoreQueryData(
    ["worklistOrders", worklistId],
    ordersQuery,
    {
      idField: "id",
      subscribe: true,
    },
    { cacheTime: 0 }
  );
};
