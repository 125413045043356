import { useAppSelector } from "./redux";
import { IWorkitem } from "@queue/types";
import { firestore } from "../firebase";
import {
  where,
  query,
  collection,
  CollectionReference,
} from "firebase/firestore";
import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import _ from "lodash";

export const useWorkitems = (orderId: string, worklistId?: string) => {
  const storeId = useAppSelector((state) => state.store.storeId);

  let itemsQuery = query(
    collection(
      firestore,
      "stores",
      storeId,
      "workitems"
    ) as CollectionReference<IWorkitem>,
    where("order", "==", orderId)
  );

  if (worklistId) {
    itemsQuery = query(
      collection(
        firestore,
        "stores",
        storeId,
        "workitems"
      ) as CollectionReference<IWorkitem>,
      where("order", "==", orderId),
      where("worklist", "==", worklistId)
    );
  }

  return useFirestoreQueryData(
    ["workitems", orderId, worklistId],
    itemsQuery,
    {
      subscribe: true,
      idField: "id",
    },
    {
      select: (data) => _.sortBy(data, "sortKey"),
      cacheTime: 0,
    }
  );
};
