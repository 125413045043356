import React, { useEffect } from "react";
import { firestore } from "../firebase";
import { useAppDispatch } from "../hooks/redux";
import { storeSlice } from "../redux/store";
import { doc } from "firebase/firestore";
import { useFirestoreDocumentData } from "@react-query-firebase/firestore";

interface Props {
  storeId: string;
}
export const Sync: React.FC<Props> = ({ storeId }) => {
  const dispatch = useAppDispatch();

  const settingsRef = doc(
    firestore,
    "stores",
    storeId,
    "settings",
    "preferences"
  );

  const preferences = useFirestoreDocumentData(
    ["settings", storeId],
    settingsRef
  );

  useEffect(() => {
    if (preferences?.data?.integrations?.cloudKitchen?.cloudCenterId) {
      dispatch(
        storeSlice.actions.setCloudCenterId(
          preferences?.data.integrations.cloudKitchen.cloudCenterId
        )
      );
    }
  }, [preferences, dispatch]);

  return null;
};
