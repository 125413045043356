import React, { useCallback } from "react";
import { useOrders } from "../../hooks/orders";
import { Workorder } from "../Workorder";
import { FullScreenHandle } from "react-full-screen";
import useDimensions from "react-cool-dimensions";
import { calculateBestWidth } from "../Worklist";
import { Card, CardHeader, Stack } from "@mui/material";
import { useWorklist } from "../../hooks/useWorklists";
import { Scrollbars } from "react-custom-scrollbars-2";

interface Props {
  worklistId: string;
  handleFullscreen: FullScreenHandle;
}

export const WorklistGroup: React.FC<Props> = ({
  worklistId,
  handleFullscreen,
}) => {
  const orders = useOrders({});

  const filteredOrders = orders?.data?.filter(
    (o) => !o?.worklistCompleted?.includes(worklistId)
  );

  const { observe, width } = useDimensions();
  const worklist = useWorklist(worklistId);

  const toggleFullscreen = useCallback(() => {
    if (handleFullscreen.active) {
      handleFullscreen.exit();
    } else {
      handleFullscreen.enter();
    }
  }, [handleFullscreen]);

  const itemWidth = calculateBestWidth(width, 400);

  return (
    <div
      ref={observe}
      style={{
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card
        onClick={toggleFullscreen}
        style={{
          position: "relative",
          display: "flex",
          margin: 10,
          height: 70,
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
          justifyContent: "center",
        }}
      >
        <CardHeader title={worklist?.data?.name || "Default"} />
      </Card>

      <Scrollbars
        style={{ height: "80vh", paddingBottom: 120, marginBottom: 100 }}
        autoHide
      >
        <Stack direction="row" flexWrap="wrap">
          {filteredOrders?.map((order) => (
            <Workorder
              order={order}
              worklistId={worklistId}
              key={order.id}
              width={itemWidth}
            />
          ))}
          <div style={{ height: 150 }} />
        </Stack>
      </Scrollbars>
    </div>
  );
};
