import React from "react";
// import { Offline } from "react-detect-offline";
// import red from "@material-ui/core/colors/red";
// import { Typography } from "@material-ui/core";

export const KDSOffline: React.FC = () => {
  return null;
  // return (
  //   <Offline>
  //     <div
  //       style={{
  //         height: 50,
  //         backgroundColor: red[800],
  //         display: "flex",
  //       }}
  //     >
  //       <Typography
  //         style={{
  //           alignSelf: "center",
  //           flex: 1,
  //           textAlign: "center",
  //           color: "white",
  //         }}
  //       >
  //         OFFLINE
  //       </Typography>
  //     </div>
  //   </Offline>
  // );
};
