import React from "react";
import { useDispatch } from "react-redux";
import {
  List,
  ListItem,
  ListItemText,
  Card,
  CircularProgress,
  Button,
  Stack,
} from "@mui/material";
import { useDatabaseValue } from "@react-query-firebase/database";
import { database, auth } from "../firebase";
import { ref } from "firebase/database";
import { storeSlice } from "../redux/store";

interface Props {
  storeId: string;
}

const Store: React.FC<Props> = ({ storeId }) => {
  const storeNameRef = ref(database, `/branches/${storeId}/settings/name`);

  const storeName = useDatabaseValue<string>(
    ["storeName", storeId],
    storeNameRef
  );

  const dispatch = useDispatch();

  const handleSelectStore = (storeId: string) => {
    dispatch(storeSlice.actions.setStoreId(storeId));
  };

  if (storeName?.isLoading) {
    return <CircularProgress />;
  }

  if (!storeName) {
    return null;
  }

  return (
    <ListItem key={storeId} onClick={() => handleSelectStore(storeId)} divider>
      <ListItemText>{storeName?.data}</ListItemText>
    </ListItem>
  );
};

interface SelectProps {
  storeIds: string[];
}

const SelectStore: React.FC<SelectProps> = ({ storeIds }) => {
  return (
    <Stack>
      <Card sx={{ margin: 1 }}>
        <Stack>
          <List>
            {storeIds.map((storeId) => {
              return <Store storeId={storeId} key={storeId} />;
            })}
          </List>
        </Stack>
      </Card>
      <Button
        onClick={() => {
          auth.signOut();
        }}
      >
        Sign out
      </Button>
    </Stack>
  );
};

export default SelectStore;
