import { createSlice } from "@reduxjs/toolkit";

interface State {
  darkmode: boolean;
  storeIds: string[];
}

const initialState: State = {
  darkmode: false,
  storeIds: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    toggleDarkmode: (state) => {
      state.darkmode = !state.darkmode;
    },
    setStoreIds: (state, { payload }) => {
      state.storeIds = payload;
    },
  },
});
