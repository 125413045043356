import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

export const firebaseConfig = {
  apiKey: "AIzaSyC7lrtUS5lzA54OPICemxpGwqrNzaQDsn0",
  authDomain: "queue-api.firebaseapp.com",
  databaseURL: "https://queue-api.firebaseio.com",
  projectId: "queue-api",
  storageBucket: "queue-api.appspot.com",
  messagingSenderId: "1063204016925",
  appId: "1:1063204016925:web:f1ff415d08cb4c068487f4",
  measurementId: "G-7TM0C59EHN",
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;

export const auth = getAuth(firebaseApp);

export const firestore = getFirestore(firebaseApp);

export const functions = getFunctions(firebaseApp);

export const database = getDatabase(firebaseApp);
