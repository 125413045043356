import React, { useCallback, useState } from "react";
import { Card, CardHeader, CardActions, Button, Box } from "@mui/material";
import { IOrder, WorkStatus } from "@queue/types";
import { useWorkitems } from "../hooks/workitems";
import _ from "lodash";
import { WorkitemRow } from "./WorkitemRow";
import { List } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { BrandAvatar } from "./BrandAvatar";
import { completeOrderThunk } from "../thunks/completeOrder";
import { firestore } from "../firebase";
import { useFirestoreWriteBatch } from "@react-query-firebase/firestore";
import { doc, writeBatch, arrayUnion } from "firebase/firestore";

interface Props {
  order: IOrder;
  worklistId?: string;
  width: number;
}

export const Workorder: React.FC<Props> = ({ order, worklistId, width }) => {
  const workitems = useWorkitems(order.id, worklistId);

  const storeId = useAppSelector((state) => state.store.storeId);
  const [completing, setCompleting] = useState(false);

  const dispatch = useAppDispatch();

  const batch = writeBatch(firestore);

  const mutation = useFirestoreWriteBatch(batch);

  const handleCompleteOrder = useCallback(async () => {
    setCompleting(true);
    if (worklistId) {
      const storeRef = doc(firestore, "stores", storeId);

      const posOrderRef = doc(
        storeRef,
        "openItems",
        "cart",
        "openOrders",
        order.id
      );

      workitems?.data?.forEach((item: any) => {
        const wRef = doc(storeRef, "workitems", item.id);
        batch.update(wRef, { workstatus: WorkStatus.Completed });
      });

      batch.update(posOrderRef, {
        worklistCompleted: arrayUnion(worklistId),
      });

      mutation.mutate();
      return;
    }

    await dispatch(completeOrderThunk(order));
  }, [order, dispatch, storeId, worklistId, workitems]);

  if (workitems.isLoading || !(workitems?.data?.length || 0 > 0)) {
    return null;
  }

  return (
    <Box sx={{ width }}>
      <Card
        sx={{ margin: 1 }}
        style={{
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
        }}
      >
        <CardHeader
          avatar={<BrandAvatar source={order.source} />}
          subheader={order.externalOrderCode || order.name}
          titleTypographyProps={{ variant: "h4" }}
          title={order.orderNumber ? `# ${order.orderNumber}` : ""}
        />
        <List sx={{ flex: 1 }}>
          {workitems?.data?.map((workitem) => {
            return (
              <WorkitemRow
                key={workitem.id}
                workitem={workitem}
                storeId={storeId}
              />
            );
          })}
        </List>
        <CardActions>
          <Button
            size="large"
            style={{ flex: 1 }}
            variant="contained"
            onClick={handleCompleteOrder}
            disabled={completing}
            color="success"
          >
            Ready
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
