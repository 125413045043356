import { combineReducers } from "@reduxjs/toolkit";
import { userSlice } from "../redux/user";
import { storeSlice } from "../redux/store";
import { displaySlice } from "../redux/display";

const rootReducer = combineReducers({
  store: storeSlice.reducer,
  user: userSlice.reducer,
  display: displaySlice.reducer,
});

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;
