import React, { useCallback } from "react";
import { useOrders } from "../../hooks/orders";
import FlexView from "react-flexview";
import { Workorder } from "../Workorder";
import { FullScreenHandle } from "react-full-screen";
import useDimensions from "react-cool-dimensions";
import { calculateBestWidth } from "../Worklist";
import { Card, CardHeader, Typography } from "@mui/material";
import { useWorklist } from "../../hooks/useWorklists";
import Scrollbars from "react-custom-scrollbars-2";

interface Props {
  worklistId: string;
  handleFullscreen: FullScreenHandle;
}

export const WorklistGroupOrder: React.FC<Props> = ({
  worklistId,
  handleFullscreen,
}) => {
  const orders = useOrders({ worklistId });
  const worklist = useWorklist(worklistId);

  const toggleFullscreen = useCallback(() => {
    if (handleFullscreen.active) {
      handleFullscreen.exit();
    } else {
      handleFullscreen.enter();
    }
  }, [handleFullscreen]);

  return (
    <div
      style={{
        flex: 1,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Card
        onClick={toggleFullscreen}
        style={{
          position: "relative",
          display: "flex",
          margin: 10,
          height: 70,
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
          justifyContent: "space-between",
        }}
      >
        <CardHeader title={worklist?.data?.name || "Default"} />
        <Typography variant="h3" style={{ margin: 5 }}>
          {orders?.data?.length || 0}
        </Typography>
      </Card>

      <Scrollbars style={{ height: "100vh", paddingBottom: 120 }} autoHide>
        <FlexView wrap>
          {orders?.data?.map((order) => (
            <Workorder order={order} key={order.id} width={200}/>
          ))}
        </FlexView>
        <div style={{ height: 150 }} />
      </Scrollbars>
    </div>
  );
};
