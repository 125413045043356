import { IWorkitem, WorkStatus } from "@queue/types";
import React, { useCallback } from "react";
import {
  colors,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Button,
  Avatar,
  Chip,
  Stack,
  Divider,
} from "@mui/material";
import { firestore } from "../firebase";
import { displaySlice } from "../redux/display";
import { useAppDispatch } from "../hooks/redux";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { doc } from "firebase/firestore";
import _ from "lodash";

interface Props {
  workitem: IWorkitem;
  storeId: string;
}

export const WorkitemRow: React.FC<Props> = ({ workitem, storeId }) => {
  const statusColor = [
    WorkStatus.PendingComplete,
    WorkStatus.Completed,
  ].includes(workitem.workstatus)
    ? colors.green[500]
    : colors.yellow[700];

  const workitemRef = doc(
    firestore,
    "stores",
    storeId,
    "workitems",
    workitem.id
  );

  const mutation = useFirestoreDocumentMutation(workitemRef, { merge: true });

  const handleClick = useCallback(() => {
    let status = WorkStatus.Completed;

    if (workitem.workstatus === WorkStatus.Open) {
      status = WorkStatus.PendingComplete;
    }

    mutation.mutate({
      completedAt: Date.now(),
      status,
      workstatus: status,
    });
  }, [workitem]);

  const dispatch = useAppDispatch();

  const handleShowInstruction = useCallback(() => {
    dispatch(displaySlice.actions.setKdsInstructions(workitem));
  }, [workitem, dispatch]);

  const productOptions = _.sortBy(_.map(workitem?.productOptions), "sortKey");

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        disabled={workitem.workstatus === WorkStatus.Completed}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              backgroundColor: statusColor,
            }}
          >
            {workitem.quantity}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{ paddingLeft: workitem.parentLineItem ? 6 : 0 }}
          primary={`${workitem.productName}`}
          secondary={workitem.comment}
        />
        {workitem.kdsInst && (
          <ListItemSecondaryAction>
            <Button onClick={handleShowInstruction}>Info</Button>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      <Stack spacing={1} direction="row" marginTop={1} marginLeft={1}>
        {productOptions?.map((po) => (
          <Chip label={`${po.name}`} />
        ))}
      </Stack>
      <Divider sx={{ marginTop: 1 }} />
    </>
  );
};
