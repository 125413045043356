import React from "react";
import { map } from "lodash";
import { useTheme } from "@mui/material";
import { Worklist } from "./Worklist";
import { FullScreenHandle } from "react-full-screen";
import { useAppSelector } from "../hooks/redux";
import { selectWorklistGroup } from "../selectors/store";
import FlexView from "react-flexview";
import { KDSOffline } from "./Offline";

interface Props {
  handleFullscreen: FullScreenHandle;
}

export const WorklistGroup: React.FC<Props> = ({ handleFullscreen }) => {
  const group = useAppSelector(selectWorklistGroup);

  const theme = useTheme();

  return (
    <FlexView
      className="worklistGroup"
      column
      style={{
        backgroundColor: theme.palette.background.default,
        height: "100vh",
      }}
    >
      <KDSOffline />
      <FlexView className="worklist" style={{ flex: 1 }}>
        {map(group?.worklists, (_, worklistId) => (
          <Worklist
            key={worklistId}
            worklistId={worklistId}
            handleFullscreen={handleFullscreen}
          />
        ))}
      </FlexView>
    </FlexView>
  );
};
