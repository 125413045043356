import React from "react";
import logo from "../img/logo.png";
import { Typography } from "@mui/material";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { auth } from "../firebase";
import { EmailAuthProvider } from "firebase/auth";

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: "redirect",
  signInOptions: [EmailAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  },
};

const Login: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 30,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="Logo" height={100} width={100} />
        <Typography variant="h1" style={{ margin: 40 }}>
          KDS
        </Typography>
      </div>

      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </div>
  );
};

export default Login;
