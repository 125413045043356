import React, { useCallback, useMemo } from "react";
import { map, padStart } from "lodash";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Chip,
  Card,
  CardHeader,
  CardActions,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import { IWorkitem, OrderKind, OrderSourceType } from "@queue/types";
import { Colors } from "../colors";
import Timeago from "react-timeago";
import { AppState } from "../reducers";
import { useSelector } from "react-redux";
import FlexView from "react-flexview";
import foodpanda from "../img/panda.png";
import uber from "../img/uber.jpg";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { storeSlice } from "../redux/store";
import { displaySlice } from "../redux/display";
import { firestore } from "../firebase";
import { doc } from "firebase/firestore";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { deepOrange } from "@mui/material/colors";
import _ from "lodash";

interface Props {
  workitem: IWorkitem;
}

export const Workitem: React.FC<Props> = ({ workitem }) => {
  const storeId = useSelector((state: AppState) => state.store.storeId);

  const itemSelected = useAppSelector(
    (state) => state.store.selectedWorkitemId === workitem.id
  );

  const dispatch = useAppDispatch();
  const workitemRef = doc(
    firestore,
    "stores",
    storeId,
    "workitems",
    workitem.id
  );

  const mutation = useFirestoreDocumentMutation(workitemRef, { merge: true });

  const handleSelect = useCallback(() => {
    if (itemSelected) {
      mutation.mutate({
        staff: "kds",
        completedAt: Date.now(),
        status: 1,
        workstatus: "COMPLETED",
        workitem,
      });

      if (workitem.orderKind === OrderKind.QUICK) {
        return;
      }

      // try {
      //   let lineitemRef = firestore
      //     .collection("stores")
      //     .doc(storeId)
      //     .collection("openItems")
      //     .doc("cart")
      //     .collection("openLineItems")
      //     .doc(workitem.lineItemId);

      //   if (workitem.paid) {
      //     lineitemRef = firestore
      //       .collection("stores")
      //       .doc(storeId)
      //       .collection("lineItems")
      //       .doc(workitem.lineItemId);
      //   }

      //   lineitemRef.update({
      //     completedAt: Date.now(),
      //     completed: true,
      //   });
      // } catch (e) {}
    } else {
      dispatch(storeSlice.actions.setSelectedWorkitemId(workitem.id));
    }
  }, [dispatch, itemSelected, storeId, workitem]);

  const handleShowInstruction = useCallback(() => {
    dispatch(displaySlice.actions.setKdsInstructions(workitem));
  }, [workitem, dispatch]);

  return useMemo(() => {
    const colorIndex = workitem.orderNumber ? workitem.orderNumber % 20 : 0;
    const color = itemSelected ? "#ffeb3b" : Colors[colorIndex];

    let name = workitem.productName;

    if (workitem.parentName) {
      name = `${workitem.parentName} | ${name}`;
    }

    if (workitem.variantName) {
      name = `${name} ${workitem.variantName}`;
    }

    const subheader = `${workitem?.externalOrderCode || workitem.orderName}`;
    return (
      <Card
        style={{
          flex: 1,
          margin: 10,
          MozUserSelect: "none",
          WebkitUserSelect: "none",
          msUserSelect: "none",
        }}
      >
        <FlexView style={{ backgroundColor: color, height: 15 }} />
        <CardHeader
          style={{ padding: 10, paddingBottom: 0 }}
          title={name}
          titleTypographyProps={{ variant: "h6" }}
          subheader={subheader}
          avatar={
            <Avatar sx={{ backgroundColor: deepOrange[500] }}>
              {workitem.quantity}
            </Avatar>
          }
          action={
            <>
              {workitem.source === OrderSourceType.UBER && (
                <img src={uber} height={40} alt={""} />
              )}
              {workitem.source === OrderSourceType.DELIVERY_HERO && (
                <img src={foodpanda} height={40} alt={""} />
              )}
            </>
          }
        />
        {workitem.comment && (
          <Typography
            variant="h5"
            style={{
              alignSelf: "center",
              maxWidth: 300,
              textAlign: "center",
            }}
          >
            {"* " + workitem.comment}
          </Typography>
        )}

        {workitem.productOptions && (
          <>
            {map(workitem.productOptions, (productOption, id) => {
              const quantity = productOption.quantity;
              let name = productOption.name;

              if (productOption.subName) {
                name = name + " | " + productOption.subName;
              }

              const displayName = `${quantity} x ${name}`;

              return (
                <Chip
                  key={id}
                  style={{ margin: 5, padding: 20 }}
                  label={<Typography variant="h6">{displayName}</Typography>}
                />
              );
            })}
          </>
        )}

        <FlexView
          style={{
            justifyContent: "space-between",
            flex: 1,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <Typography variant="h6">
            <Timeago date={workitem.createdAt} />
          </Typography>
          <Typography variant="h6">
            {`${workitem.brandName || ""} ${_.padStart(
              workitem.orderNumber + "",
              4,
              "0"
            )}`}
          </Typography>
        </FlexView>
        <CardActions>
          {workitem.kdsInst && (
            <Button
              startIcon={<HelpOutlineIcon />}
              onClick={handleShowInstruction}
            >
              Help
            </Button>
          )}
          <Button
            onClick={handleSelect}
            sx={{ flex: 1 }}
            variant={itemSelected ? "contained" : "outlined"}
            color={"success"}
          >
            OK
          </Button>
        </CardActions>
      </Card>
    );
  }, [workitem, handleSelect, itemSelected, handleShowInstruction]);
};

Workitem.whyDidYouRender = true;
